import request from './axios';

interface npcInfo {
  Address: string; //地址
  Avatar: string; // 头像
  Birthday: string;
  Banner: string; // 	轮播图，最多8张，多张用竖线隔开
  CardBack: string; // 身份证背面
  CardFront: string; // 身份证正面
  Gender: number; //性别，0：女，1：男
  Height: number | null; // 身高
  Id: number; // 添加修改
  Mobile: string; // 电话
  NickName: string; // 昵称
  NpcStatus: number; // npc状态，0:待审核,1:审核失败,2:在线,3:忙碌,4:已接单
  OrderDateEnd: string; // 接单日期结束
  OrderDateStart: string; // 接单日期开始
  OrderTimeEnd: string; // 接单时间结束
  OrderTimeStart: string; //接单时间开始
  Summary: string; // 简介
}

// 添加编辑Npc
export const addNpc = (data: npcInfo) => {
  return request({
    url: '/npc/info/add',
    method: 'post',
    data: data,
  });
};

interface npcSearch {
  NpcStatus: string | number;
  Mobile: string | number;
  NickName: string | number;
  PageSize: string | number;
  PageIndex: string | number;
}

//查询列表
export const npcList = (params: npcSearch) => {
  return request({
    url: '/npc/info/list',
    method: 'get',
    params: params,
  });
};

//查询npc详情
export const npcDetails = (id: number) => {
  return request({
    url: `/npc/info/${id}/detail`,
    method: 'put',
  });
};

interface skillInfo {
  Banner: string;
  CanPlay: number | string;
  Description: string;
  Id: number | string;
  LowestOrderCount: number | string;
  NpcId: number | string;
  SecondCategory: number | string;
  ServiceExplain: string;
  SkillTitle: string;
  UnitPrice: number | string;
  UnitType: number | string;
}
// 添加技能
export const addSkill = (data: skillInfo) => {
  return request({
    url: '/npc/skill/add',
    method: 'post',
    data: data,
  });
};

interface searchSkill {
  CanPlay: string | number;
  SecondCategory: string | number;
  FirstCategory: string | number;
  SkillTitle: string | number;
  NickName: string | number;
  NpcId: string | number;
  PageSize: string | number;
  PageIndex: string | number;
}
// 获取技能列表
export const skillList = (params: searchSkill) => {
  return request({
    url: '/npc/skill/list',
    method: 'get',
    params: params,
  });
};

// 获取技能详情
export const skillDetail = (id: number) => {
  return request({
    url: `/npc/skill/${id}/detail`,
    method: 'put',
  });
};

// 删除技能
export const skillDel = (id: number) => {
  return request({
    url: `/npc/skill/${id}/del`,
    method: 'delete',
  });
};

// 是否可玩修改
export const skillIsPlay = (id: number, CanPlay: number) => {
  return request({
    url: `/npc/skill/${id}/play`,
    method: 'put',
    data: { CanPlay },
  });
};

//获取陪玩钱包明细
export const walletInfo = (params: any): Promise<any> => {
  return request({
    url: `/npc/wallet/detail`,
    method: 'get',
    params: params,
  });
};


// skill审核拒绝
export const skillApplyReject = (id:any,RejectReason:any) => {
  return request({
    url: `/npc/skill/${id}/reject`,
    method: 'put',
    data: {RejectReason},
  });
};

// skill审核通过
export const skillApplyPassed= (id:any) => {
  return request({
    url: `/npc/skill/${id}/passed`,
    method: 'put',
  });
};

// npc审核拒绝
export const npcApplyReject = (id:any,RejectReason:any) => {
  return request({
    url: `/npc/info/${id}/reject`,
    method: 'put',
    data: {RejectReason},
  });
};

// npc审核通过
export const npcApplyPassed= (id:any) => {
  return request({
    url: `/npc/info/${id}/passed`,
    method: 'put',
  });
};


export const npcGroupList= (id:any) => {
  return request({
    url: `/npc/group/info/list`,
    method: 'get',
  });
};

export const npcGroupAdd= (data:any) => {
  return request({
    url: `/npc/group/info/add`,
    method: 'post',
    data: data
  });
};

export const npcGroupDel= (id:any) => {
  return request({
    url: `/npc/group/info/${id}/del`,
    method: 'delete'
  });
};

export const npcGroupDetail= (id:any) => {
  return request({
    url: `/npc/group/info/${id}/detail`,
    method: 'get'
  });
};


export const npcGroupDetailAdd = (data:any) => {
  return request({
    url: `/npc/group/detail/add`,
    method: 'post',
    data: data
  });
};


export const npcGroupDetailDel = (id:any) => {
  return request({
    url: `/npc/group/detail/${id}/del`,
    method: 'delete'
  });
};


export const npcGroupDetailLeader = (id:any) => {
  return request({
    url: `/npc/group/detail/${id}/leader`,
    method: 'put'
  });
};


