export const skillTableModel = [
  { title: '陪玩头像', key: 'npc', slots: { customRender: 'npc' }, width: '100px' },
  { title: '陪玩名称', dataIndex: 'NickName', key: 'NickName', width: '160px' },
  { title: '游戏分类', key: 'category', slots: { customRender: 'category' }, width: '160px' },
  { title: '技能标题', dataIndex: 'SkillTitle', key: 'SkillTitle', width: '200px' },
  { title: '单价', key: 'UnitPrice', slots: { customRender: 'price' }, width: '100px' },
  { title: '单位', dataIndex: 'UnitTypeStr', key: 'UnitTypeStr', width: '100px' },
  { title: '最低购买', dataIndex: 'LowestOrderCount', key: 'LowestOrderCount', width: '180px', slots: { customRender: 'lowest' } },
  { title: '是否可玩', dataIndex: 'CanPlay', key: 'CanPlay', width: '100px', slots: { customRender: 'canPlay' } },
  { title: '操作', key: 'action', slots: { customRender: 'action' }, width: '200px', fixed: 'right' },
];

export const skillInfoModel = {
  Banner: '',
  CanPlay: 1,
  Description: '',
  Id: 0,
  LowestOrderCount: 3,
  NpcId: '',
  NpcName: '',
  SecondCategory: '',
  ServiceExplain: '',
  SkillTitle: '',
  UnitPrice: '',
  RenewUnitPrice:'',
  UnitType: 0,
};

export const skillSearch = {
  CanPlay: '',
  SecondCategory: '',
  FirstCategory: '',
  SkillTitle: '',
  NickName: '',
  NpcId: '',
  SkillStatus:'',
  PageSize: 10,
  PageIndex: 1,
};

export const categoryTableModel = [
  { title: '名称', dataIndex: 'CategoryName', key: 'categoryName', width: '180px' },
  { title: '图片', dataIndex: 'Icon', key: 'icon', slots: { customRender: 'icon' }, width: '180px' },
  { title: '排序', dataIndex: 'Sort', key: 'Sort', width: '180px' },
  { title: '链接',  key: 'link', width: '200px', slots: { customRender: 'link' }  },
  { title: '操作', key: 'action', slots: { customRender: 'action' } },
];

export const categoryInfoModel = {
  CategoryName: '', //名称
  Icon: '', // 图片
  UnitPriceMax:0,
  UnitPriceMin:0,
  ParentId: 0, //父id
  Id: 0, // 添加修改
  Sort: 1, //排序
};

export const npcTableModel = [
  { title: '头像', key: 'name', slots: { customRender: 'avatar' }, width: '100px' },
  { title: '昵称', dataIndex: 'NickName', key: 'nickName', width: '140px' },
  { title: '电话', dataIndex: 'Mobile', key: 'mobile', width: '140px' },
  { title: '地址', dataIndex: 'Address', key: 'address', width: '200px' },
  { title: '性别', dataIndex: 'GenderStr', key: 'genderStr', width: '100px', slots: { customRender: 'gender' } },
  { title: '提成比例', key: 'CommissionRatio', slots: { customRender: 'CommissionRatio' }, width: '180px' },
  { title: '钱包余额', key: 'WalletBalance', slots: { customRender: 'WalletBalance' }, width: '100px' },
  { title: '状态', dataIndex: 'NpcStatusStr', key: 'npcStatusStr', width: '100px', slots: { customRender: 'npcStatus' } },
  { title: '接单时间', key: 'timeStart', width: '340px', slots: { customRender: 'timeStart' } },
  { title: '操作', key: 'action', fixed: 'right', width: '180px', slots: { customRender: 'action' } },
];

export const npcInfoModel = {
  Address: '', //地址
  Avatar: '', // 头像
  Banner: '', // 	轮播图，最多8张，多张用竖线隔开
  Birthday: '2000-01-01', //出生日期
  CardBack: 'http://shangyuns-pojudian.oss-cn-chengdu.aliyuncs.com/npc/202107161650031O0I7K0QQJPC0.jpg', // 身份证背面
  CardFront: 'http://shangyuns-pojudian.oss-cn-chengdu.aliyuncs.com/npc/202107161650031O0I7K0QQJPC0.jpg', // 身份证正面
  City: '',
  County: '',
  Province: '',
  Location: '',
  Gender: 0, //性别，0：女，1：男
  Height: 0, // 身高
  Id: 0, // 添加修改
  Mobile: '', // 电话
  NickName: '', // 昵称
  CommissionRatio: 60, //提成比例
  Sort: 0, //排序
  NpcStatus: 0, // npc状态，0:待审核,1:审核失败,2:在线,3:忙碌,4:已接单
  OrderDateEnd: '2021-10-01', // 接单日期结束
  OrderDateStart: '2021-07-01', // 接单日期开始
  OrderTimeEnd: '23:00:00', // 接单时间结束
  OrderTimeStart: '08:00:00', //接单时间开始
  Summary: '', // 简介
};

export const npcStatus = [
  { code: 0, value: '待审核' },
  { code: 1, value: '审核失败' },
  { code: 2, value: '在线' },
  { code: 3, value: '忙碌' },
  { code: 4, value: '已接单' },
];

export const npcSearch = {
  NpcStatus: '',
  Mobile: '',
  NickName: '',
  PageSize: 10,
  PageIndex: 1,
};

export const npcWalletTableModel = [
  { title: '用户Id', key: 'UserId', dataIndex: 'UserId', width:'180px' },
  { title: '交易状态', key: 'DetailStatusStr', dataIndex: 'DetailStatusStr' , width:'100px'},
  { title: '收支类型', key: 'AmountTypeStr', dataIndex: 'AmountTypeStr', width:'100px' },
  { title: '标题', key: 'DetailTitle', dataIndex: 'DetailTitle' , width:'100px'},
  { title: '备注', key: 'Remark', dataIndex: 'Remark', width:'420px' },
  { title: '交易成功时间', key: 'SuccessTime', dataIndex: 'SuccessTime' , width:'180px'},
  { title: '金额', key: 'link', dataIndex: 'Amount', slots: { customRender: 'amount' }, width:'100px' },
  { title: '钱包类型', key: 'WalletTypeStr', dataIndex: 'WalletTypeStr', width:'180px' },
];

export const npcWalletSearchModel = {
  DetailStatus: '',
  AmountType: '',
  BusinessId: '',
  NpcId: '',
  PageIndex: 1,
  PageSize: 10,
};

export const npcWalletDetailStatus = [
  { code: '', value: '全部状态' },
  { code: 0, value: '交易中' },
  { code: 1, value: '交易失败' },
  { code: 2, value: '交易成功' },
];

export const npcWalletAmountType = [
  { code: '', value: '全部类型' },
  { code: 0, value: '收入' },
  { code: 1, value: '支出' },
];
