
import { defineComponent, reactive, ref } from 'vue';
import { awaitWrap } from '@/util/index';
import { npcGroupList, npcGroupDel, npcGroupAdd } from '@/api/npc';
import _ from 'lodash';
import NpcDrawer from './components/npcDrawer.vue';
import { ModalError } from '@/util/message';
export default defineComponent({
  components: { NpcDrawer },
  setup() {
    const search = { NpcStatus: '', Mobile: '', NickName: '', PageSize: 10, PageIndex: 1 };
    const searchRef = reactive(_.clone(search));
    function handleReset() {
      _.assignIn(searchRef, search);
    }

    const tableColumns = [
      { title: 'Id', dataIndex: 'Id', key: 'Id' },
      { title: '分组名称', dataIndex: 'GroupName', key: 'GroupName' },
      { title: '添加时间', dataIndex: 'InsertDate', key: 'InsertDate' },
      { title: '操作', key: 'action', slots: { customRender: 'action' } },
    ];
    const tableSource = ref([]);
    const tableLoading = ref(false);
    const pagination = reactive({
      current: 1,
      total: 10,
    });
    function handlePageChange(val: any) {
      pagination.current = val;
      searchRef.PageIndex = val;
      getList();
    }
    async function getList() {
      tableLoading.value = true;
      const { code, data } = await awaitWrap(npcGroupList(searchRef));
      tableLoading.value = false;
      if (code === 200) {
        tableSource.value = data.data.DataList;
        pagination.total = data.data.RecordCount;
      }
    }

    const show = ref(false);
    const info: any = reactive({
      GroupName: '',
      Id: 0,
      leader: 0,
      NpcAddList: [],
      npcInfoList: [],
    });
    function openShowModel() {
      info.GroupName = '';
      info.Id = 0;
      show.value = true;
    }

    async function haeldAdd(callback: any) {
      await npcGroupAdd({ GroupName: info.GroupName, Id: info.Id });
      show.value = false;
      getList();
      callback();
    }

    function handleInfo(el: any) {
      info.GroupName = el.GroupName;
      info.Id = el.Id;
      show.value = true;
    }
    function handleDel(el: any) {
      ModalError('你确定要删除', async function () {
        await npcGroupDel(el.Id);
        getList();
      });
    }

    const drawerShow = ref(false);
    function handleNpcList(el: any) {
      info.Id = el.Id;
      drawerShow.value = true;
    }

    const npcVisible = ref(false);
    getList();
    return { searchRef, tableColumns, drawerShow, handleNpcList, npcVisible, handleReset, info, pagination, show, haeldAdd, handlePageChange, tableLoading, tableSource, handleDel, handleInfo, openShowModel };
  },
});
