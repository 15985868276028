
import { CloseCircleFilled, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
// <LeftCircleOutlined /><RightCircleOutlined />
import { defineComponent, ref } from 'vue';

import { Modal } from 'ant-design-vue';

export default defineComponent({
  name: 'YuiImage',
  components: { CloseCircleFilled, AModal: Modal, LeftCircleOutlined, RightCircleOutlined },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    size: {
      type: String,
      default: 'default',
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['upload-cancel'],
  setup(props: any) {
    const visible = ref(false);
    const currentImgIndex = ref(0);
    function changeVisible(index: number) {
      visible.value = true;
      currentImgIndex.value = index;
    }

    function handlNext() {
      if (props.list.length - 1 === currentImgIndex.value) {
        currentImgIndex.value = 0;
        return;
      }
      currentImgIndex.value++;
    }

    function handleLast() {
      if (0 === currentImgIndex.value) {
        currentImgIndex.value = props.list.length - 1;
        return;
      }
      currentImgIndex.value--;
    }
    return { visible, currentImgIndex, changeVisible, handlNext, handleLast };
  },
});
