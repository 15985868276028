import { RouteLocationRaw, useRouter, useRoute } from 'vue-router';

export function useInitRouter(): any {
  const router = useRouter();

  function routerPush(data: RouteLocationRaw) {
    router.push(data);
  }

  return {
    routerPush,
  };
}

export function useInitRoute(): any {
  const route = useRoute();

  function queryParams(key: string) {
    return route.query[key] || '';
  }

  return {
    route,
    queryParams,
  };
}
