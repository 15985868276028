
import { defineComponent, computed, ref, reactive, watch } from 'vue';
import NpcModel from './NpcModel.vue';
import { difference } from 'lodash';
import { npcGroupDetail, npcGroupDetailAdd, npcGroupDetailDel, npcGroupDetailLeader } from '@/api/npc';
export default defineComponent({
  components: { NpcModel },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const npcVisible: any = ref(false);
    const show = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });
    const info: any = reactive({
      GroupName: '',
      Id: 0,
      leader: 0,
      NpcAddList: [],
      npcInfoList: [],
    });

    async function getCpcGroupDetail() {
      const { data } = await npcGroupDetail(props.id);
      info.npcInfoList = data;
      info.NpcAddList = data.map((item: any) => {
        return item.NpcId;
      });
    }

    // npcGroupDetailLeader

    async function hanldeOk({ keys, rows }: any) {
      info.NpcAddList.difference;

      const addDiff = difference(keys, info.NpcAddList);

      const delDiff2: any[] = difference(info.NpcAddList, keys);

      if (addDiff.length) {
        await npcGroupDetailAdd({ GroupId: props.id, NpcAddList: addDiff });
        getCpcGroupDetail();
      }

      if (delDiff2.length) {
        //  info.npcInfoList
        const delList: any[] = info.npcInfoList.filter((item: any) => delDiff2.includes(item.NpcId));
        if (delList) {
          delList.forEach((item) => {
            handleDel(item.Id);
          });
        }
        await npcGroupDetailAdd({ GroupId: props.id, NpcAddList: addDiff });
      }

      info.NpcAddList = keys;
      info.npcInfoList = rows;
    }

    async function handleDel(id: any) {
      await npcGroupDetailDel(id);
      getCpcGroupDetail();
    }

    async function handleLeader(id: any) {
      await npcGroupDetailLeader(id);
      getCpcGroupDetail();
    }

    watch(
      () => props.visible,
      (val) => {
        val && getCpcGroupDetail();
      }
    );

    return { info, npcVisible, show, hanldeOk, handleDel, handleLeader };
  },
});
