
import { computed, defineComponent, ref, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import { ManOutlined } from '@ant-design/icons-vue';
import YImage from '@/components/modules/image/index.vue';
import { useTable } from '../../list/setup';
export default defineComponent({
  components: { AModal: Modal, ManOutlined, YImage },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:visible', 'update:keys', 'update:rows', 'ok'],
  setup(props: any, { emit }) {
    const visibleModel: any = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          selectedRowKeys.value = props.keys;
          selectedNpcList.value = props.rows;
        }
      }
    );

    const selectedRowKeys: any = ref([]);
    const selectedNpcList: any = ref([]);
    function selectedChange(keys: string, rows: any) {
      selectedRowKeys.value = keys;
      selectedNpcList.value = rows;
    }

    function handleOk() {
      visibleModel.value = false;
      emit('ok', { keys: selectedRowKeys.value, list: selectedNpcList.value });
    }

    const { tableLoading, npcStatus, pagination, searchRef, handlePageChange, getNpcList, tableSource } = useTable();
    const npcTableModel = [
      { title: '头像', key: 'name', slots: { customRender: 'avatar' }, width: '80px' },
      { title: '昵称', dataIndex: 'NickName', key: 'nickName', width: '100px' },
      { title: '电话', dataIndex: 'Mobile', key: 'mobile', width: '140px' },
      { title: '性别', dataIndex: 'GenderStr', key: 'genderStr', width: '100px', slots: { customRender: 'gender' } },
      { title: '状态', dataIndex: 'NpcStatusStr', key: 'npcStatusStr', width: '100px', slots: { customRender: 'npcStatus' } },
    ];
    function handleChange(pagination: any) {
      handlePageChange(pagination.current);
    }
    return {
      tableLoading,
      selectedRowKeys,
      selectedChange,
      handleOk,
      npcStatus,
      npcTableModel,
      pagination,
      searchRef,
      getNpcList,
      tableSource,
      visibleModel,
      handleChange,
    };
  },
});
