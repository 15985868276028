import { reactive, computed, ref, getCurrentInstance } from 'vue';
import _ from 'lodash';
import { npcInfoModel, npcSearch, npcTableModel, npcStatus } from '@/data/modules/npc';
import { addNpc, npcList, npcDetails, npcApplyReject, npcApplyPassed } from '@/api/npc';
import { awaitWrap } from '@/util/index';
import moment from 'moment';
import { message } from 'ant-design-vue';
import { ModalReason } from '@/util/message';
// ModalError,
import { useInitRouter } from '@/util/router';
import Map from '@/components/map/map';
//表单hooks
export function useTable(): any {
  const { routerPush } = useInitRouter();

  const tableColumns = npcTableModel;

  const searchRef = reactive(_.clone(npcSearch));

  const pagination = reactive({
    current: 1,
    total: 10,
  });

  const tableSource = ref([]);
  const tableLoading = ref(false);

  async function getNpcList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(npcList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  getNpcList();

  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getNpcList();
  }

  function handleSkillList(el: any) {
    routerPush({
      path: '/npc/skill',
      query: {
        NickName: el.NickName,
      },
    });
  }

  function handleReset() {
    _.assignIn(searchRef, npcSearch);
  }

  function handleWalletInfo(Id: number) {
    routerPush({
      path: '/npc/wallet/info',
      query: {
        id: Id,
      },
    });
  }

  //审核通过
  async function handleApplyPassed(id: number) {
    await npcApplyPassed(id);
    message.success('成功');
    getNpcList();
  }

  //审核拒绝
  function handleApplyRefuse(id: number) {
    ModalReason('填写拒绝理由', async function (val: any) {
      await npcApplyReject(id, val);
      message.success('成功');
      getNpcList();
    });
  }

  return {
    tableLoading,
    handleReset,
    npcStatus,
    tableColumns,
    pagination,
    searchRef,
    getNpcList,
    handleSkillList,
    handlePageChange,
    handleApplyRefuse,
    handleApplyPassed,
    handleWalletInfo,
    tableSource,
  };
}

//编辑信息hooks
export function useNpcInfo(): any {
  const aMap = new Map({ plugins: ['AMap.PlaceSearch', 'AMap.Geocoder'] }, 'container');

  const { proxy }: any = getCurrentInstance();

  const formRef = ref();

  //弹出层控制
  const showModel = ref(false);

  //验证规则
  const roleAddNpc = {
    Avatar: [{ required: true, message: '请输入上传头像', trigger: 'change' }],
    Banner: [{ required: true, message: '请输入上传轮播图', trigger: 'change' }],
    CardBack: [{ required: true, message: '请输入身份证正面', trigger: 'change' }],
    CardFront: [{ required: true, message: '请输入身份证反面', trigger: 'change' }],
    OrderDateEnd: [{ required: true, message: '请输入接单时间', trigger: 'change' }],
    Mobile: [{ required: true, message: '请输入电话', trigger: 'change' }],
    Birthday: [{ required: true, message: '请输入出生日期', trigger: 'change' }],
    NickName: [{ required: true, message: '请输入昵称', trigger: 'change' }],
  };

  //添加npc信息
  const npnInfo = reactive(_.clone(npcInfoModel));

  //计算属性转换时间为后台数据需要时间
  // const orderTime = computed({
  //   get() {
  //     return {
  //       OrderDateStart:npnInfo.OrderDateStart? moment(`${npnInfo.OrderDateStart}`, 'YYYY-MM-DD'): moment(),
  //       OrderDateEnd: npnInfo.OrderDateEnd? moment(`${npnInfo.OrderDateEnd}`, 'YYYY-MM-DD'): moment(),
  //       OrderTimeStart: npnInfo.OrderTimeStart? moment(`${npnInfo.OrderTimeStart}`, 'HH:mm:ss'): moment(),
  //       OrderTimeEnd: npnInfo.OrderTimeEnd? moment(`${npnInfo.OrderTimeEnd}`, 'HH:mm:ss'): moment(),
  //     };
  //   },
  //   set(val: any) {
  //     console.log(val)
  //   },
  // });

  const dateStartCalc = computed({
    get() {
      return npnInfo.OrderDateStart ? moment(npnInfo.OrderDateStart, 'YYYY-MM-DD') : moment();
    },
    set(val: any) {
      npnInfo.OrderDateStart = val.format('YYYY-MM-DD');
    },
  });
  const dateEndCalc = computed({
    get() {
      return npnInfo.OrderDateEnd ? moment(npnInfo.OrderDateEnd, 'YYYY-MM-DD') : moment();
    },
    set(val: any) {
      npnInfo.OrderDateEnd = val.format('YYYY-MM-DD');
    },
  });
  const timeEndCalc = computed({
    get() {
      return npnInfo.OrderTimeEnd ? moment(npnInfo.OrderTimeEnd, 'HH:mm:ss') : moment();
    },
    set(val: any) {
      npnInfo.OrderTimeEnd = val.format('HH:mm:ss');
    },
  });
  const timeStartCalc = computed({
    get() {
      return npnInfo.OrderTimeStart ? moment(npnInfo.OrderTimeStart, 'HH:mm:ss') : moment();
    },
    set(val: any) {
      npnInfo.OrderTimeStart = val.format('HH:mm:ss');
    },
  });

  const birthdayComputed = computed({
    get() {
      return npnInfo.Birthday ? moment(npnInfo.Birthday, 'YYYY-MM-DD') : moment();
    },
    set(val: any) {
      npnInfo.Birthday = val.format('YYYY-MM-DD');
    },
  });

  const avatarComputed = computed({
    get() {
      return npnInfo.Avatar ? npnInfo.Avatar.split('|') : [];
    },
    set(val: Array<string>) {
      formRef.value.clearValidate('Avatar');

      npnInfo.Avatar = val.join('|');
    },
  });

  const bannerComputed = computed({
    get() {
      return npnInfo.Banner ? npnInfo.Banner.split('|') : [];
    },
    set(val: Array<string>) {
      formRef.value.clearValidate('Banner');

      npnInfo.Banner = val.join('|');
    },
  });
  const cardBackComputed = computed({
    get() {
      return npnInfo.CardBack ? npnInfo.CardBack.split('|') : [];
    },
    set(val: Array<string>) {
      formRef.value.clearValidate('CardBack');

      npnInfo.CardBack = val.join('|');
    },
  });
  const CardFrontComputed = computed({
    get() {
      return npnInfo.CardFront ? npnInfo.CardFront.split('|') : [];
    },
    set(val: Array<string>) {
      formRef.value.clearValidate('CardFront');
      npnInfo.CardFront = val.join('|');
    },
  });

  const addressComputed = computed({
    get() {
      return npnInfo.Province + npnInfo.City + npnInfo.County + npnInfo.Address;
    },
    set(val: string) {
      npnInfo.Province = '';
      npnInfo.City = '';
      npnInfo.County = '';
      npnInfo.Address = val;
    },
  });

  //调用方法
  function openShowModel(): void {
    _.assignIn(npnInfo, npcInfoModel);
    showModel.value = true;
  }

  //获取地址信息
  function handleBlurAddress() {
    aMap
      .getLocation(addressComputed.value)
      .then((ref: any) => {
        const addr = ref.addressComponent;
        npnInfo.Address = addressComputed.value.replace(`${addr.province}`, '').replace(`${addr.city}`, '').replace(`${addr.district}`, '');
        npnInfo.Province = addr.province;
        npnInfo.City = addr.city;
        npnInfo.County = addr.district;
        npnInfo.Location = `${ref.location.lng},${ref.location.lat}`;
      })
      .catch(() => {
        npnInfo.Address = '';
        message.warning('地址转化失败！请重新填写');
      });
  }

  async function handleEdit(el: { Id: number }) {
    const { code, data } = await awaitWrap(npcDetails(el.Id));
    if (code === 200) {
      _.assignIn(npnInfo, data.data);
      showModel.value = true;
    }
  }

  async function haeldAddNpc(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    if (validate.code === 200) {
      const ret = await awaitWrap(addNpc(npnInfo));
      if (ret.code === 200) {
        message.success('编辑成功');
        showModel.value = false;
        proxy.getNpcList();
      }
    }
    callback();
  }

  return { npnInfo, showModel, roleAddNpc, birthdayComputed, dateStartCalc, dateEndCalc, timeStartCalc, timeEndCalc, formRef, CardFrontComputed, cardBackComputed, bannerComputed, avatarComputed, addressComputed, openShowModel, haeldAddNpc, handleEdit, handleBlurAddress };
}
