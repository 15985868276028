import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_box = _resolveComponent("search-box")
  const _component_a_divider = _resolveComponent("a-divider")
  const _component_a_table = _resolveComponent("a-table")
  const _component_table_box = _resolveComponent("table-box")
  const _component_a_input = _resolveComponent("a-input")
  const _component_a_form_item = _resolveComponent("a-form-item")
  const _component_a_form = _resolveComponent("a-form")
  const _component_drawer_box = _resolveComponent("drawer-box")
  const _component_npcDrawer = _resolveComponent("npcDrawer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_search_box, { onHaeldConfirm: _ctx.openShowModel }, null, 8, ["onHaeldConfirm"]),
    _createVNode(_component_table_box, {
      onPageChange: _ctx.handlePageChange,
      pagination: _ctx.pagination
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          dataSource: _ctx.tableSource,
          loading: _ctx.tableLoading,
          pagination: false,
          rowKey: "Id",
          columns: _ctx.tableColumns
        }, {
          action: _withCtx(({ record }) => [
            _createVNode("span", null, [
              _createVNode("a", {
                href: "javascript:;",
                onClick: ($event: any) => (_ctx.handleInfo(record))
              }, "查看详情", 8, ["onClick"]),
              _createVNode(_component_a_divider, { type: "vertical" }),
              _createVNode("a", {
                href: "javascript:;",
                onClick: ($event: any) => (_ctx.handleNpcList(record))
              }, "查看陪玩", 8, ["onClick"]),
              _createVNode(_component_a_divider, { type: "vertical" }),
              _createVNode("a", {
                href: "javascript:;",
                onClick: ($event: any) => (_ctx.handleDel(record))
              }, "删除", 8, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["dataSource", "loading", "columns"])
      ]),
      _: 1
    }, 8, ["onPageChange", "pagination"]),
    _createVNode(_component_drawer_box, {
      title: "编辑分组",
      width: "380px",
      visible: _ctx.show,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = $event)),
      onDrawerSubmit: _ctx.haeldAdd
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "vertical",
          ref: "formRef",
          model: _ctx.info
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "分组名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.info.GroupName,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.info.GroupName = $event)),
                  placeholder: "昵称"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "onDrawerSubmit"]),
    _createVNode(_component_npcDrawer, {
      id: _ctx.info.Id,
      visible: _ctx.drawerShow,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.drawerShow = $event))
    }, null, 8, ["id", "visible"])
  ], 64))
}