import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"12px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_image = _resolveComponent("y-image")
  const _component_ManOutlined = _resolveComponent("ManOutlined")
  const _component_a_tag = _resolveComponent("a-tag")
  const _component_a_table = _resolveComponent("a-table")
  const _component_a_modal = _resolveComponent("a-modal")

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visibleModel,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.visibleModel = $event)),
    width: "800px",
    onOk: _ctx.handleOk,
    title: "添加陪玩"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, "已选择" + _toDisplayString(_ctx.selectedRowKeys.length) + "位陪玩", 1),
      _createVNode(_component_a_table, {
        dataSource: _ctx.tableSource,
        "row-selection": { selectedRowKeys: _ctx.selectedRowKeys, onChange: _ctx.selectedChange },
        scroll: { y: 500 },
        loading: _ctx.tableLoading,
        onChange: _ctx.handleChange,
        pagination: _ctx.pagination,
        rowKey: "Id",
        columns: _ctx.npcTableModel
      }, {
        avatar: _withCtx(({ record }) => [
          _createVNode(_component_y_image, {
            list: [record.Avatar],
            size: "mini",
            showRemove: false
          }, null, 8, ["list"])
        ]),
        gender: _withCtx(({ record }) => [
          _createVNode(_component_a_tag, {
            color: record.Gender ? 'blue' : 'red'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ManOutlined),
              _createVNode("span", null, _toDisplayString(record.GenderStr), 1)
            ]),
            _: 2
          }, 1032, ["color"])
        ]),
        npcStatus: _withCtx(({ record }) => [
          _createVNode(_component_a_tag, {
            color: ['orange', 'red', 'success', '#f50', 'default'][record.NpcStatus]
          }, {
            default: _withCtx(() => [
              _createVNode("span", null, _toDisplayString(record.NpcStatusStr), 1)
            ]),
            _: 2
          }, 1032, ["color"])
        ]),
        _: 1
      }, 8, ["dataSource", "row-selection", "loading", "onChange", "pagination", "columns"])
    ]),
    _: 1
  }, 8, ["visible", "onOk"]))
}