import AMapLoader from '@amap/amap-jsapi-loader';
const mapKey = '18cd477cb648d662786981b84203c914';

export default class Map {
  map: any;
  AMap: any;
  markers: any;
  geocoder: any;
  constructor(config: any, container: any) {
    this.AMap = '';
    this.map = '';
    this.markers = [];
    this.geocoder = '';

    // var Profile = Vue.extend(MapDom);
    // // 创建 Profile 实例，并挂载到一个元素上。
    // this.mapInstance = new Profile().$mount();
    // document.body.appendChild(this.mapInstance.$el);

    this.load(config, container);
  }

  load(config: any, container: any): Promise<any> {
    const self = this;
    return new Promise((resolve) => {
      AMapLoader.load({
        key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
        ...config,
      }).then((AMap: any) => {
        this.AMap = AMap;
        if (container) {
          this.map = new this.AMap.Map(container, {
            resizeEnable: true,
            zoom: 12,
          });
        }
        resolve(self);
      });
    });
  }

  //地址转坐标
  getLocation(address: string) {
    return new Promise((resolve, reject) => {
      if (!this.geocoder) {
        this.geocoder = new this.AMap.Geocoder();
      }

      this.geocoder.getLocation(address, function (status: any, result: any) {
        console.log(result, address);
        if (result.geocodes && result.geocodes.length) {
          resolve(result.geocodes[0]);
          return;
        }
        reject();
      });
    });
  }

  //搜索地址添加标记
  marker(configMarker: any) {
    const marker = new this.AMap.Marker({
      map: this.map,
      ...configMarker,
      content: '<div class="marker-box"></div>',
    });
    if (configMarker.label) {
      marker.setLabel({
        direction: 'top',
        offset: new this.AMap.Pixel(0, -6),
        content: `<div class='info'>${configMarker.label}</div>`, //设置文本标注内容
      });
    }
    this.markers.push(marker);
    this.map.setFitView(this.markers, false, [0, 0, 0, 0], 14);
  }

  //删除标记
  removeMarker() {
    this.map.remove(this.markers);
    this.markers = [];
  }

  // //搜索
  // search(keywords: any) {
  //   return new Promise((resolve) => {
  //     if (!this.placeSearch) {
  //       this.placeSearch = this.AMap.PlaceSearch();
  //     }
  //     this.placeSearch.search(keywords, function (status: any, result: any) {
  //         resolve(result.poiList.pois);
  //     });
  //   });
  // }

  //地址标记
  locationMarker(type: any, markerInfo: any) {
    //#FF6600
    const contentDom = `<div class="${type}-theme-marker map-marker"></div>`;
    const labelContentDom = `<div class='${type}-theme-info map-marker-info'><div class='map-marker-info-label'>${markerInfo.label}</div><div class='map-marker-info-value'>${markerInfo.value}</div></div><div class='map-marker-sharp'></div>`;

    const marker = new this.AMap.Marker({
      position: markerInfo.position,
      content: contentDom,
      anchor: 'center',
      offset: new this.AMap.Pixel(0, 0),
    });
    marker.setMap(this.map);
    marker.setLabel({
      direction: 'top',
      offset: new this.AMap.Pixel(0, -12), //设置文本标注偏移量
      content: labelContentDom, //设置文本标注内容
    });
  }
}
