import { createVNode, h } from 'vue';

import { Modal, Input } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import { awaitWrap } from '@/util/index';

export function ModalError(content: string, callbakc: any) {
  Modal.confirm({
    title: '确认操作',
    icon: createVNode(ExclamationCircleOutlined),
    content: content,
    onOk: callbakc,
    // async onOk() {
    //   const { code } = await awaitWrap(callbakc());
    //   return new Promise((resolve, reject) => {
    //     if (code === 200) {
    //       message.success('删除成功');
    //       getCategoryList();
    //       resolve('');
    //     } else {
    //       reject();
    //     }
    //   });
    // },
  });
}

export function ModalReason(title: string = '确认', callbakc: any) {
  let value = '';
  Modal.confirm({
    title: title,
    // icon: createVNode(ExclamationCircleOutlined),
    content: h(Input.TextArea, {
      onInput({target}) {
        value = target.value
      },
    }),
    onOk() {
      callbakc(value);
    },
    // async onOk() {
    //   const { code } = await awaitWrap(callbakc());
    //   return new Promise((resolve, reject) => {
    //     if (code === 200) {
    //       message.success('删除成功');
    //       getCategoryList();
    //       resolve('');
    //     } else {
    //       reject();
    //     }
    //   });
    // },
  });
}

// export function  ModalError(content:string,callbakc:any){
//   Modal.confirm({
//     title: '删除确认',
//     icon: createVNode(ExclamationCircleOutlined),
//     content: content,
//     okType: 'danger',
//     onOk:callbakc,
//     // async onOk() {
//     //   const { code } = await awaitWrap(callbakc());
//     //   return new Promise((resolve, reject) => {
//     //     if (code === 200) {
//     //       message.success('删除成功');
//     //       getCategoryList();
//     //       resolve('');
//     //     } else {
//     //       reject();
//     //     }
//     //   });
//     // },
//   });
// }
